import { toast } from "#/lib/toast";
import { components } from "#/types.ts/swagger";

export const isDSPPaused = (paused_until: "indefinite" | number) => {
  if (!paused_until) return false;
  if (paused_until === "indefinite") return true;
  const d = new Date().getTime();
  if (paused_until > d) return true;
};

export const DspStatus = (paused_until: string | number) => {
  if (!paused_until) return "pause";
  const d = new Date().getTime();
  if (paused_until === "indefinite") {
    return "unpause";
  } else if (Number(paused_until) > d) {
    return "edit";
  } else {
    return "pause";
  }
};

export enum DispatchProviders {
  "first" = "first",
  "sauce" = "sauce",
  "example-events" = "example-events",
  "dlivrd" = "dlivrd",
  "cartwheel" = "cartwheel",
  "ihd" = "ihd",
}

export enum DSP {
  "uber" = "uber",
  "doordash" = "doordash",
  "grubhub" = "grubhub",
  "google" = "google",
  "doordash-retail" = "doordash-retail",
  "skipthedishes" = "skipthedishes",
  "toast" = "toast",
  "flipdish" = "flipdish",
  "chownow" = "chownow",
  "jet" = "jet",
  "deliveroo" = "deliveroo",
  "wonder" = "wonder",
}

export enum POS {
  square = "square",
  toast = "toast",
  zonal = "zonal",
  aloha = "aloha",
  stream = "stream",
  clover = "clover",
  orange = "orange",
  akru = "akru",
}

export const TOP_DSPS = [DSP.grubhub, DSP.uber, DSP.doordash];

export const FEATURE_FLAGS = {
  AI_DESCRIPTIONS: [POS.square, POS.stream, POS.clover, POS.toast],
  DSP_MANUAL_BIND: [DSP.deliveroo, DSP.skipthedishes, DSP.jet, DSP.grubhub],
  LOCATION_SPECIFIC_SAVE_CATALOG: [POS.zonal],
  HOLIDAY_HOURS: [
    DSP.uber,
    DSP.doordash,
    DSP["doordash-retail"],
    DSP.grubhub,
    DSP.wonder,
  ],
  SHARED_CATALOG: [POS.square, POS.zonal],
  CHOOSE_MENU_SOURCES: [POS.toast],
  SKIP_OAUTH_SOURCES: [POS.toast],
  DISPATCH_SETTING_INGEST_SWITCH: [POS.toast, POS.square],
  DISABLE_MULTI_DSP_LOCATIONS_PER_MERCHANT: [
    DSP.doordash,
    DSP["doordash-retail"],
  ],
  MENU_EDITING_DISABLED_SOURCES: [POS.toast, POS.zonal, POS.akru, POS.orange],
  DUPLICATE_LOCATION: [POS.toast],
  NON_BINDABLE: [DSP.grubhub, DSP.google, DSP.skipthedishes, DSP.jet],
  PREFERRED_DSPS: [DSP.doordash, DSP.google, DSP.grubhub, DSP.uber],
  NO_LOCATION_OVERRIDES: [POS.clover],
  REQUIRES_TAX_TO_PUBLISH: [DSP.flipdish, DSP.google],
  DELAY_TO_REMOVE: [DSP.grubhub],
};

export const DSP_PAUSE_OPTIONS: {
  value: any;
  label: any;
}[] = [
  {
    label: "Until I turn it back on",
    value: "indefinite",
  },
  {
    label: "15 min",
    value: "15",
  },
  {
    label: "30 min",
    value: "30",
  },
  {
    label: "1 hour",
    value: "60",
  },
  {
    label: "Until a specific time",
    value: "custom",
  },
];

export const isValidPublish = ({
  dsp,
  t,
  location,
}: {
  t: (key: string) => string;
  dsp: string;
  location: components["schemas"]["MerchantLocation"];
}): { reason: "no_tax" | "not_approved"; valid: boolean } => {
  if (
    FEATURE_FLAGS.REQUIRES_TAX_TO_PUBLISH.includes(dsp as DSP) &&
    typeof location?.sales_tax_percentage === "undefined"
  ) {
    toast.warn(
      t("Please set your sales tax percentage before publishing to Flipdish"),
    );
    return {
      valid: false,
      reason: "no_tax",
    };
  }

  return {
    valid: true,
    reason: "not_approved",
  };
};

export const addOverride = (arr: any[], dsp: string, value: any) => {
  const newArr = [...arr];

  if (dsp === "all") {
    /** If the price is set for all, remove dsp overrides */
    return [];
  }

  const index = newArr.findIndex((x) => x.source === dsp);
  if (index > -1) {
    newArr[index] = { ...newArr[index], value };
  } else {
    newArr.push({ source: dsp, value });
  }

  return newArr;
};

export const MenuPublishFailureCode = {
  /** dsp issue, retry publish  */
  DSP_INTERNAL_FAILURE: "DSP_INTERNAL_FAILURE",
  /** menu hours are not populated */
  MENU_SCHEDULE_MISSING: "MENU_SCHEDULE_MISSING",
  /** menu contains no data */
  MENU_BLANK: "MENU_BLANK",
  /** menu content contains issues, need to be investigatred */
  MENU_CONTENT_ISSUE: "MENU_CONTENT_ISSUE",
  /**
   * Menu contains items not allowed to be published
   * ex. weapons?
   */
  MENU_COMPLIANCE_ISSUE: "MENU_COMPLIANCE_ISSUE",
  MENU_FAILURE_UNKNOWN: "MENU_FAILURE_UNKNOWN",
};

export enum OnboardingFailureCode {
  /** dsp store to integrate is not found */
  DSP_ACCOUNT_NOT_FOUND = "DSP_ACCOUNT_NOT_FOUND",
  DSP_LOCATION_IS_NOT_ELIGIBLE = "DSP_LOCATION_IS_NOT_ELIGIBLE",
  /** dsp store can not be integrated for a non unique location id */
  DUPLICATE_LOCATION_ID = "DUPLICATE_LOCATION_ID",
  /** dsp store is already activated */
  DSP_LOCATION_ALREADY_ACTIVE = "DSP_LOCATION_ALREADY_ACTIVE",
  ONBOARDING_FAILURE_UNKNOWN = "ONBOARDING_FAILURE_UNKNOWN",
}

export enum FindIDLink {
  deliveroo = "https://intercom.help/stream-f226eb7e1191/en/articles/9275216-how-to-connect-deliveroo",
  "grubhub" = "https://stream-docs.vercel.app/docs/dsp-integrations/grubhub/how-to-connect#4-copy-grubhub-merchant-id",
  "jet" = "https://intercom.help/stream-f226eb7e1191/en/articles/9992913-how-to-connect-just-eat-takeaway-jet",
  "skipthedishes" = "https://intercom.help/stream-f226eb7e1191/en/articles/9991908-how-to-connect-skip-the-dishes",
}
